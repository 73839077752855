import React from 'react'
import Layout from '../components/Layout'
import SEO from '../components/Seo'

import Cover from '../components/Cover'
import VideoBox from '../components/VideoBox'
import CTA from '../components/Cta'

import coverImage from '../images/speculazione.jpg'

import posterSpeculazione from '../images/poster-speculazione.png'

const Impulsivita = () => (
  <Layout>
    <SEO title="I rischi della Speculazione" />
    <Cover
      title="I rischi della Speculazione"
      color="green"
      bg={coverImage}
      icon=""
      link="#video"
    >
      <p>
        Per quanto attraenti possano sembrare, le manovre speculative sono le
        più pericolose. Soprattutto per chi non è abituato a “cavalcarle” da
        professionista. Il breve video che segue mostra come l’eccitazione non
        sia mai una buona consigliera, quando si tratta di investire i propri
        risparmi. Soprattutto se non si ha la competenza per prevedere le
        conseguenze.
      </p>
    </Cover>
    <VideoBox ytID="IXYQfZ0d0gM" poster={posterSpeculazione} />
    <CTA />
  </Layout>
)

export default Impulsivita
